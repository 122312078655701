import React from "react"
import Layout from "../components/Layout"

export default function FAQ() {
  return (
    <Layout title={"Success"}>
      <section className="hero">
        <div className="container text-center">
          <div className="container-sm">
            <h2>Uh oh! There's been an error with your purchase. Please contact tracie@livingwisely.org for assistance.</h2>
          </div>


        </div>
      </section>
    </Layout>
  )
}
